/**
 * @param {*} date 
 * @param {*} formatStr 
 * @description 时间格式化
 */
export function formatTime(date, formatStr = 'YY/MM/DD hh:mm:ss') {
    if (typeof date === 'number' || typeof date === 'string') {
        if (date.toString().length == 10) {
            date *= 1000;
        }
        date = new Date(date);
    }
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    let formatedDateStr = '';
    switch (formatStr) {
        case 'YY/MM/DD hh:mm:ss':
            formatedDateStr = [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(
                formatNumber).join(':');
            break;
        case 'YY-MM-DD hh:mm:ss':
            formatedDateStr = [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(
                formatNumber).join(':');
            break;
        case 'YY/MM/DD':
            formatedDateStr = [year, month, day].map(formatNumber).join('/')
            break;
        case 'YY-MM-DD':
            formatedDateStr = [year, month, day].map(formatNumber).join('-')
            break;
        case 'YY.MM':
            formatedDateStr = [year, month].map(formatNumber).join('.')
            break;
    }
    return formatedDateStr
}
export const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}