<template>
	<div class="design-step ul-none">
		<div class="content">
			<div class="context c w">
				<div class="left-card">
					<p class="name">{{ name }}</p>
					<p class="en">{{ enName }}</p>
					<p class="desc" v-html="content"></p>
					<button class="more-btn" @click="toPage('/aboutus')">
						<span>MORE</span>
						<div class="icon">
							<el-icon>
								<DArrowRight />
							</el-icon>
						</div>
					</button>
				</div>
				<div class="right-card">
					<ul>
						<li v-for="(item, i) in setionCard" :key="i" @click="toPage(item.url, item.query)">
							<div class="li-c">
								<p class="name z-title-1">{{ item.name }}</p>
								<p class="name-en z-title-1">{{ item.nameEn }}</p>
								<img :src="item.img" alt="" />
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="step-num">
				<div class="step-item-con">
					<ul class="c w">
						<li v-for="item in stepList" :key="item.id">
							<div class="item-warp">
								<p class="step">{{ item.stepNumber }}</p>
								<p class="name z-title-1">{{ item.name }}</p>
								<p class="en z-title-1">{{ item.enName }}</p>
								<p class="time">{{ item.activityDate }}</p>
								<p class="desc">{{ item.introduction }}</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import {
	reactive,
	defineProps
} from "vue";
import {
	DArrowRight
} from "@element-plus/icons-vue";
import {
	useRouter
} from 'vue-router'
const props = defineProps({
	content: {
		type: String,
		default: ''
	},
	name: {
		type: String,
		default: ''
	},
	enName: {
		type: String,
		default: ''
	},
	stepList: {
		type: Array,
		default() {
			return []
		}
	}
})
const router = useRouter()
const setionCard = reactive([{
	name: '报名入口',
	nameEn: 'SIGN UP',
	img: require("../../assets/home/card/c1.png"),
	url: '/account/login'
},
{
	name: '专家入口',
	nameEn: 'EXOERT',
	img: require("../../assets/home/card/c2.png"),
	url: '/expert/index'
},
{
	name: '活动入口',
	nameEn: 'ACTIVITY',
	img: require("../../assets/home/card/c3.png"),
	url: ''
},
{
	name: '下载专区',
	nameEn: 'DOWNLOAD',
	img: require("../../assets/home/card/c4.png"),
	url: '/nav/details',
	query: {
		point: 7
	}
},
]);

function toPage(path, query) {
	if (path) {
		router.push({
			path,
			query
		})
	}

}
</script>

<style lang="scss" scoped>
.design-step {
	height: 600px;
	background-image: linear-gradient(to bottom, #fff, #b0e1d5);
	padding-bottom: 50px;

	p {
		margin: 0;
	}

	.content {
		position: relative;
		margin: 0 auto;

		.step-num {
			padding-top: 315px;
		}

		.context {
			position: relative;
			color: #fff;

			.left-card {
				position: absolute;
				top: -30px;
				left: 0;
				z-index: 2;
				width: 650px;
				background-color: #bf511e;
				padding: 40px 100px 40px 40px;

				.name {
					font-size: 20px;
					color: #fff;
				}

				.en {
					font-size: 12px;
					margin: 4px 0 20px 0;
				}

				.desc {
					max-height: 154px;
					width: 500px;
					letter-spacing: 2px;
					line-height: 2;
					display: -webkit-box !important;
					overflow: hidden;
					text-overflow: ellipsis;
					word-break: break-all;
					-webkit-line-clamp: 5;
					-webkit-box-orient: vertical !important;
				}

				.more-btn {
					margin-top: 30px;
					width: 300px;
					height: 39px;
					background-color: transparent;
					border: 1px solid #fff;
					border-radius: 5px;
					color: #fff;
					cursor: pointer;
					padding: 0 20px;
					opacity: 0.7;

					a {
						color: #fff;
						text-decoration: none;
					}

					&:hover {
						opacity: 1;
					}

					.icon {
						float: right;
					}
				}
			}

			.right-card {
				position: absolute;
				right: -7px;
				top: 20px;
				z-index: 2;
				color: #333;
				font-size: 12px;

				ul {
					display: flex;
					align-items: center;

					li {
						box-sizing: border-box;
						display: flex;
						justify-content: center;
						align-items: center;
						// padding: 80px 58px;
						background-color: #eceded;
						margin-right: 10px;
						border-top-left-radius: 80px;
						border-top-right-radius: 80px;
						cursor: pointer;
						transition: all 0.5s;
						width: 150px;
						height: 240px;
						text-align: center;

						.li-c {

							.name {
								color: #333;
								font-size: 16px;
								margin-bottom: 6px;
							}

							.name-en {
								color: #333;
								font-size: 12px;
								margin-bottom: 25px;
							}
						}

						p {
							text-align: center;
						}

						&:hover {
							transform: translateY(-20px);
							box-sizing: 4px 3px 3px rgba(0, 0, 0, 1);
						}

						img {
							height: 50px;
						}
					}
				}
			}
		}

		.step-item-con {
			border-top: 2px solid #bf511e;

			ul {
				display: flex;
				padding-left: 100px;

				li {
					position: relative;
					width: 300px;

					.item-warp {
						margin-top: -40px;
						margin-left: 20px;
					}

					&::before {
						content: "";
						display: inline-block;
						font-size: 20px;
						height: 80px;
						width: 4px;
						background-color: #bf511e;
					}

					&::after {
						content: "";
						display: inline-block;
						position: absolute;
						top: 80px;
						left: -3px;
						width: 10px;
						height: 10px;
						background-color: #bf511e;
						border-radius: 50%;
					}

					p {
						line-height: 1.5;
					}

					.step {
						font-size: 50px;
						color: #bf511e;
						font-weight: 700;
					}

					.name {
						font-size: 20px;
						color: #000;
					}

					.en {
						font-size: 12px;
						margin-bottom: 4px;
						color: #000;
					}
				}
			}
		}
	}
}
</style>
