<template>
	<div class="z-contaier c w">
		<swiper :slidesPerView="autoplayOptions.slidesPerView" :spaceBetween="autoplayOptions.spaceBetween"
			:navigation="autoplayOptions.navigation" :centeredSlides="autoplayOptions.centeredSlides"
			:loop="autoplayOptions.loop" :autoplay="autoplayOptions.autoplay" :effect="autoplayOptions.effect"
			:grabCursor="autoplayOptions.grabCursor">
			<swiper-slide v-for="item in list" :key="item.id" @click="toPage">
				<img :src="item[keyName]" alt="" />
			</swiper-slide>
		</swiper>
	</div>
</template>
<script setup>
	import {
		onMounted,
		reactive,
		ref,
		defineProps
	} from "vue";

	import {
		useRouter
	} from 'vue-router'
	// 导入swiper中vue组件
	import {
		Swiper,
		SwiperSlide
	} from "swiper/vue";
	import "swiper/swiper-bundle.css";
	// 导入swiper模块
	import SwiperCore, {
		// 设置底部小圆点
		Pagination,
		// 设置两边的箭头
		Navigation,
		// 动画效果
		EffectCoverflow,
		// 自动播放
		Autoplay,
	} from "swiper";

	SwiperCore.use([Pagination, Navigation, EffectCoverflow, Autoplay]);

	// 导入swiper的样式，注意此处导入的样式

	//自动轮播的配置
	const autoplayOptions = {
		loop: false,
		centeredSlides: false, //设定为true时，当前的active slide 会居中
		navigation: true, // 是否使用前进后退按钮
		spaceBetween: 50, // 轮播图间隙
		slidesPerView: 2, // 展示多少张
		effect: "slide", // 切换效果
		grabCursor: true, // 该选项给Swiper 使用者提供小小的贴心应用，设置为true 时，鼠标覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状。（根据浏览器形状有所不同）
		pagination: {
			clickable: false, // 点击分页器的指示点分页器会控制Swiper切换
		},
		coverflowEffect: {
			// 以3D界面的形式显示出来的方式
			rotate: 50,
			stretch: 0,
			depth: 100,
			modifier: 1,
			slideShadows: true,
		},
	};
	let router = useRouter()
	let props = defineProps({
		list: {
			type: Array,
			default () {
				return []
			}
		},
		keyName: {
			type: String,
			default: 'thumbnail'
		}
	})

	function toPage() {
		router.push({
			path: '/review'
		})
	}
</script>
<style lang="scss" scoped>
	.swiper-container {
		.swiper-slide {
			height: 400px;
		}
	}

	.z-contaier {
		position: relative;

		img {
			width: 100%;
			height: 100%;
		}
	}
</style>
