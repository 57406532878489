<template>
	<div class="event-news c w">
		<slot name="header"></slot>
		<div class="con">
			<event-news-item v-for="item in list" :key="item.id" :item="item"></event-news-item>
		</div>
	</div>
</template>

<script setup>
	import zTitle from "@/components/zTitle";
	import eventNewsItem from "./eventNewsItem";
	import {
		reactive,
		defineProps
	} from "vue";
	let props = defineProps({
		list: {
			type: Array,
			default () {
				return []
			}
		}
	})
	const obj = reactive({
		tag: require("../../assets/home/other/c1.png"),
	});
</script>

<style scoped lang='scss'>
	.con {
		display: flex;
		flex-wrap: wrap;
	}
</style>
