<template>
	<div class="win-award c w">
		<slot name="first"></slot>
		<win-award-item v-for="item in list" :key="item" @click="toPage(item)" :item="item"></win-award-item>
	</div>
</template>

<script setup>
	import {
		reactive,
		defineProps
	} from "vue";

	import {
		useRouter
	} from "vue-router";

	import winAwardItem from "@/components/winAward/winAwardItem";
	const route = useRouter();
	const props = defineProps({
		list: {
			type: Array,
			default () {
				return []
			}
		},
	})

	function toPage(item) {
		route.push({
			path: "/work/detail",
			query: {
				id: item.id
			}
		});
	}
</script>

<style scoped lang='scss'>
	.win-award {
		display: flex;
		flex-wrap: wrap;
		padding: 80px 0;
	}

	.first-item {
		width: 100%;
		height: 100%;
	}
</style>
