<template>
	<div class="tool-bar ul-none">
		<ul>
			<li class="flex-column-center" v-for="(item, i) in toolList" :key="item.id"
				@mouseenter="handleMouseEnter(i)" @mouseleave="handleMouseLeave(i)">
				<img :src="item.img" :alt="item.name" :class="'img' + item.id" @click="login(item.url,item.query)" />
				<span> {{ item.name }}</span>
				<div v-show="item.isShow">
					<div class="sub-tool flex-column-center" v-if="item.child">
						<span v-for="s in item.child" :key="s" @click="login(s.url)">{{ s.name }}</span>
					</div>
					<div class="sub-tool flex-column-center" v-if="item.wx">
						<img :src="item.wx" alt="" />
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script setup>
	import {
		reactive,
		watch
	} from "vue";
	import {
		useRouter
	} from 'vue-router'
	import {
		getBottomGet,
	} from "@/api/publish";
	import {
		useWebsite
	} from "@/pinia/index";
	import {
		storeToRefs
	} from "pinia";
	const router = useRouter()
	const toolList = reactive([{
			id: 1,
			name: "官方公众号",
			img: require("../assets/tool/t1.png"),
			isShow: false,
			// wx: require("../assets/home/qrcode/gzh.jpg"),
		},
		{
			id: 2,
			name: "报名入口",
			img: require("../assets/tool/t2.png"),
			child: [{
					name: "院校报名",
					url: '/account/login'
				},
				{
					name: "学生报名",
					url: '/account/login'
				},
				{
					name: "教师报名",
					url: '/account/login'
				},
				{
					name: "设计师报名",
					url: '/account/login'
				},
			],
			isShow: false,
		},
		{
			id: 3,
			name: "专家入口",
			img: require("../assets/tool/t3.png"),
			isShow: false,
			url: "/expert/index"
		},
		{
			id: 4,
			name: "活动入口",
			img: require("../assets/tool/t4.png"),
			isShow: false,
			child: [{
				name: "专区",
			}, ],
		},
		{
			id: 5,
			name: "下载专区",
			img: require("../assets/tool/t5.png"),
			isShow: false,
			url: '/nav/details',
			query: {
				point: 7
			}
		},
	]);
	const handleMouseEnter = (i) => {
		toolList[i].isShow = true;
	};
	const handleMouseLeave = (i) => {
		toolList[i].isShow = false;
	};

	function login(path, query) {
		console.log(path, 'path');
		if (path) {
			router.push({
				path: path,
				query
			})
		}
	}

	const {
		websiteMsg
	} = storeToRefs(useWebsite());
	
	watch(() => websiteMsg.value, (newVAl) => {
		toolList[0].wx = websiteMsg.value.wxImg
	}, {
		immediate: true,
		deep: true
	})
</script>

<style scoped lang='scss'>
	$bg: #bf511e;
	$color: #f0e3c3;

	.tool-bar {
		position: fixed;
		top: calc(50% - 270px);
		right: 30px;
		color: $color;
		z-index: 99;

		ul {
			li {
				position: relative;
				width: 90px;
				height: 90px;
				background-color: $bg;
				border-radius: 50%;
				margin-bottom: 10px;
				cursor: pointer;
				font-size: 12px;

				.sub-tool {
					width: 150px;
					position: absolute;
					top: 0;
					right: 102px;
					background-color: $bg;
					padding-top: 20px;
					border-radius: 6px;

					img {
						width: 80%;
						padding-bottom: 20px;
					}

					&::after {
						content: "";
						position: absolute;
						right: -26px;
						top: 30px;
						display: inline-block;
						width: 0;
						height: 0;
						border: 15px solid transparent;
						border-left-color: $bg;
					}

					span {
						text-align: center;
						border-bottom: 1px solid $color;
						margin-bottom: 23px;
					}
				}

				.img1 {
					width: 40px;
				}

				.img2 {
					width: 38px;
					margin-bottom: 5px;
				}

				.img3 {
					width: 40px;
				}

				.img4 {
					width: 45px;
				}

				.img5 {
					width: 50px;
				}
			}
		}
	}
</style>
