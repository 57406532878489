<template>
	<banner-style1 :position="1"></banner-style1>
	<design-step :content="homeList.aboutContent" :name="homeList.aboutName" :enName="homeList.aboutEnName"
		:stepList="homeList.stepList"></design-step>
	<proposition en1="PROPOSITION" en2="ARENA" enTitle="命题赛场" :list="homeList.themeCompetition"></proposition>
	<div class="c w">
		<z-title align="left" title="NON PROPOSITION" titleEn="ARENA" titleCh="非命题赛场" color="#1a2e63" colorEnSize="18px"
			colorEn="#1a2e63" titleSize="18px" margin="0 0 40px 0"></z-title>
	</div>
	<div class="content-warp">
		<work-type v-for="item in homeList.otherThemeCompetition" :key="item.id" :list="item.otherCompetitionList">
			<template #header>
				<z-title align="center" :title="item.otherName" :titleEn="item.otherEnName" color="#bf511e"></z-title>
			</template>
		</work-type>
	</div>
	<win-award :list="homeList.caseList">
		<template #first>
			<div class="win-award-title z-title-1">
				<p>BOUTIQUE</p>
				<p>CASES</p>
				<p class="name">
					<el-icon size="30px">
						<ArrowRightBold />
					</el-icon>精品案例
				</p>
			</div>
		</template>
	</win-award>
	<event-news :list="homeList.newsList">
		<template #header>
			<z-title align="left" title="EVENT" titleEn="NEWS" titleCh="赛事动态" color="#1a2e63" colorEnSize="18px"
				colorEn="#1a2e63" titleSize="18px"></z-title>
		</template>
	</event-news>
	<division-list :list="homeList.areaList">
		<template #header>
			<z-title align="left" title="DIVISION" titleEn="LIST" titleCh="赛区列表" color="#1a2e63" colorEnSize="18px"
				titleSize="18px" colorEn="#1a2e63" margin="0 0 40px 0"></z-title>
		</template>
	</division-list>
	<review-past :list="homeList.historyList">
		<template #header>
			<z-title align="left" title="REVIEW OF" titleEn="PREVIOUS" titleCh="往届回顾" color="#1a2e63" colorEnSize="18px"
				titleSize="18px" colorEn="#1a2e63" margin="0 0 40px 0"></z-title>
		</template>
	</review-past>
	<Cooperate :list="homeList.unionList">
		<template #header>
			<z-title align="left" title="COOPERATIVE" titleEn="UNIT" titleCh="合作单位" color="#1a2e63" colorEnSize="18px"
				titleSize="18px" colorEn="#1a2e63" margin="0 0 40px 0"></z-title>
		</template>
	</Cooperate>
	<tool-bar></tool-bar>
</template>
<script setup>
	import {
		ArrowRight
	} from "@element-plus/icons";
	import Cooperate from "@/components/cooperate";
	import ToolBar from "@/components/ToolBar";
	import DesignStep from "@/components/designStep";
	import proposition from "@/components/proposition";
	import workType from "@/components/workType";
	import winAward from "@/components/winAward";
	import eventNews from "@/components/eventNews";
	import DivisionList from "@/components/divisionList";
	import reviewPast from "@/components/reviewPast";
	import zTitle from "@/components/zTitle";
	import {
		getHomeContent
	} from '@/api/publish'

	import {
		ref,
		reactive,
		onDeactivated,
		onActivated
	} from "vue";
	import {
		useRouter
	} from "vue-router";
	const route = reactive(useRouter());

	function toPage(url) {
		route.push(url);
	}
	const homeList = reactive({
		aboutContent: '',
		aboutName: '',
		aboutEnName: '',
		stepList: [],
		unionList: [],
		themeCompetition: [],
		otherThemeCompetition: [],
		areaList: [],
		caseList: [],
		historyList: [],
		newsList: []
	})
	getHomeContent().then(res => {
		homeList.aboutContent = res.data.aboutContent
		homeList.aboutName = res.data.aboutName
		homeList.aboutName = res.data.aboutName
		homeList.aboutEnName = res.data.aboutEnName

		homeList.stepList = res.data.stepList
		homeList.unionList = res.data.unionList
		homeList.themeCompetition = res.data.themeCompetition
		homeList.otherThemeCompetition = res.data.otherThemeCompetition
		homeList.areaList = res.data.areaList
		homeList.caseList = res.data.caseList
		homeList.historyList = res.data.historyList
		homeList.newsList = res.data.newsList
	})

	let homeScrollTop = ref(0)
	onActivated(() => {
		window.scrollTo(0, homeScrollTop.value)
		window.addEventListener('scroll', handleScroll);
	})

	onDeactivated(() => {
		window.removeEventListener('scroll', handleScroll);
	})

	function handleScroll() {
		var scrollT = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
		homeScrollTop.value = scrollT
	}
</script>
<script>
	export default {
		name: 'home',
	}
</script>

<style lang='scss' scoped>
	.content-warp {
		background: linear-gradient(to bottom, #b1e2d6, #f5f9f7);
	}

	.banner-img {
		width: 100%;
	}

	::v-deep .el-radio-group {
		width: 100%;
	}

	::v-deep .el-radio-button {
		flex: 1;
	}

	::v-deep .el-radio-button__inner {
		font-size: 16px;
		padding: 10px 23px;
		width: 100%;
	}

	.win-award-title {
		width: 350px;
		height: 450px;
		margin: 25px;

		p {
			font-size: 18px;
		}

		.name {
			font-size: 30px;
			margin-top: 10px;
		}
	}

	::v-deep .el-radio-group {
		flex: 1;
	}
</style>
