<template>
	<div class="division-list ul-none c w">
		<slot name="header"></slot>
		<ul>
			<li v-for="item in list" :key="item.id">
				<router-link :to="'/division/detail?id='+item.id">{{ item.name }}</router-link>
			</li>
		</ul>
	</div>
</template>

<script setup>
	import zTitle from "@/components/zTitle";
	import {
		reactive,
		defineProps
	} from "vue";
	const obj = reactive({
		tag: require("../../assets/home/other/c1.png"),
	});
	const props = defineProps({
		list: {
			type: Array,
			default () {
				return []
			}
		}
	})
</script>

<style scoped lang='scss'>
	.division-list {
		padding-top: 80px;

		ul {
			display: flex;
			flex-wrap: wrap;

			li {
				background-color: #ddddde;
				border-radius: 4px;
				margin: 10px 20px 10px 0;
				cursor: pointer;
				box-sizing: border-box;
				border: 1px solid #ddddde;
				width: 125px;
				text-align: center;

				a {
					display: inline-block;
					color: #000;
					text-decoration: none;
					padding: 10px 15px;
				}

				&:hover {
					background-color: #fff;
					border: 1px solid $themeColor;
					color: $themeColor;
				}
			}
		}
	}
</style>
