<template>
	<div class="review-past c w">
		<slot name="header"></slot>
		<swiper :list="list"></swiper>
	</div>
</template>

<script setup>
	import {
		reactive,
		defineProps
	} from "vue";

	import zTitle from "@/components/zTitle";
	import swiper from "@/components/swiper";

	let props = defineProps({
		list: {
			type: Array,
			default () {
				return []
			}
		}
	})
	const obj = reactive({
		img: require("../../assets/home/other/c1.png"),
	});
</script>

<style lang="scss" scoped>
	.review-past {
		padding: 80px 0;
	}
</style>
