<template>
	<div class="proposition-item">
		<slot>
			<div class="proposition-item-content" @click="toPage(item.id)">
				<img :src="item.bgImg" />
			</div>
		</slot>
	</div>
</template>

<script setup>
	import {
		defineProps,
		computed
	} from 'vue'
	import {
		formatTime
	} from '@/utils/date'
	import {
		useRouter
	} from 'vue-router';
	const router = useRouter()

	function toPage(id) {
		router.push({
			path: '/proposition/detail',
			query: {
				id: id,
				type: '2'
			}
		})
	}
	const props = defineProps({
		item: {
			type: Object,
		}
	})
	const startDate = computed(() => {
		return formatTime(new Date(props.item.startDate).getTime(), 'YY.MM')
	})
	const endDate = computed(() => {
		return formatTime(new Date(props.item.endDate).getTime(), 'YY.MM')
	})
</script>

<style scoped lang='scss'>
	.proposition-item {
		width: 370px;
		height: 200px;
		margin: 15px;

		.proposition-item-content {
			position: relative;
			width: 100%;
			height: 100%;
			background-size: 100% 100%;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
</style>
