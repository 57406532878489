<template>
	<div class="work-type-item" :style="{ backgroundImage: `url(${item.bgImg})` }">
		<div class="work-footer">
			<button @click="toPage(item.id)">
				点击查看
				<el-icon>
					<DArrowRight />
				</el-icon>
			</button>
		</div>
	</div>
</template>

<script setup>
import {
	DArrowRight
} from "@element-plus/icons-vue";
import {
	useRouter
} from 'vue-router'
import {
	reactive,
	defineProps
} from "vue";
const router = useRouter()

function toPage(id) {
	router.push({
		path: '/proposition/detail',
		query: {
			id: id,
			type: '1'
		}
	})
}
const props = defineProps({
	item: {
		type: Object
	}
})
const obj = reactive({
	name: "幼儿组（3-6岁）",
	en1: "PRESCHOOL",
	en2: "CROUP",
	img: require("../../../assets/home/other/a4.png"),
});
</script>

<style scoped lang='scss'>
.work-type-item {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 370px;
	height: 200px;
	box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 9%);
	margin: 15px;
	padding: 20px 40px;
	background-repeat: no-repeat;
	background-size: 100% 100%;

	.work-desc {
		h3 {
			font-size: 18px;
			margin-bottom: 15px;
		}

		p {
			font-weight: 700;
			font-size: 12px;
		}
	}

	.work-pic {
		img {
			width: 70px;
		}
	}

	.work-footer {
		padding-bottom: 15px;

		button {
			width: 220px;
			height: 39px;
			background-color: #bf511e;
			color: #fff;
			border: none;
			cursor: pointer;
			border-radius: 8px;
			padding: 0 20px;

			.el-icon {
				position: relative;
				top: 3px;
				float: right;
			}

			&:hover {
				border: 2px solid $themeColor;
				background-color: #fff;
				color: $themeColor;
			}
		}
	}
}
</style>
