<template>
	<div class="event-new-item" @click="toPage(item.id)">
		<div class="new-pic">
			<el-image :src="item.thumbnail" fit="scale-down"></el-image>
		</div>
		<div class="new-content">
			<div class="name">
				<span class="over-text2">{{item.title}}</span>
			</div>
			<div class="tool flex-row-between">
				<span class="time">{{item.addDate}}</span>
				<span class="to">点击查看</span>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		defineProps
	} from 'vue'
	import {
		useRouter
	} from 'vue-router'
	let router = useRouter()
	let props = defineProps({
		item: {
			type: Object,
			default () {
				return {}
			}
		}
	})

	function toPage(id) {
		router.push({
			path: '/dynamic',
			query: {
				id
			}
		})
	}
</script>

<style scoped lang='scss'>
	.event-new-item {
		display: flex;
		justify-content: space-between;
		width: 580px;
		height: 119px;
		cursor: pointer;
		margin-top: 40px;

		&:nth-child(even) {
			margin-left: 40px;
		}

		.new-pic {
			width: 240px;

			.el-image {
				width: 100%;
				height: 100%;
			}
		}

		.new-content {
			flex: 1;
			padding: 6px 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.name {
				span {
					display: inline-block;
					font-size: 15px;
					font-weight: 700;

					&:hover {
						color: $themeColor;
						text-decoration: underline;
					}
				}
			}

			.tool {
				color: #a6a6a7;
				font-size: 12px;
			}
		}
	}
</style>
